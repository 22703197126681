import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import HomePage from '../components/pages/HomePage';
import { homePageStrings } from '../resources/strings/strings-en';
import LanguageContext from '../contexts/LanguageContext';
import { StaticTranslationProvider } from '../contexts/StaticTranslationContext';
import StrapiDataContext from '../contexts/StrapiDataContext';
import Layout from '../components/common/Layout/Layout';

const IndexPage_en = () => {
  const remoteData = useStaticQuery(remoteDataQuery);

  return (
    <StrapiDataContext.Provider value={remoteData}>
      <StaticTranslationProvider translations={homePageStrings}>
        <LanguageContext.Provider value="en">
          <Layout>
            <HomePage data={remoteData} />
          </Layout>
        </LanguageContext.Provider>
      </StaticTranslationProvider>
    </StrapiDataContext.Provider>
  );
};

// TODO: try to find a way of defining only one query for all languages, since [string interpolation isn't an option](https://github.com/gatsbyjs/gatsby/issues/3186)
const remoteDataQuery = graphql`
  query GetHomePageContentEn {
    staticContent: strapiHomePage {
      mainSectionTitle {
        en
      }
      mainSectionSubtitle {
        en
      }
      methodologySubtitle {
        en
      }
      methodologyText {
        en
      }
      staffMemberText {
        en
      }
    }
    methodologySteps: allStrapiMethodologyStep(
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title {
            en
          }
          description {
            en
          }
          image {
            localFile {
              ...FluidImage
            }
          }
        }
      }
    }
    services: allStrapiService(sort: { fields: position, order: ASC }) {
      edges {
        node {
          title {
            en
          }
          subtitle {
            en
          }
          icon {
            localFile {
              ...FluidImage
            }
          }
        }
      }
    }
    caseStudies: allStrapiCaseStudy(sort: { fields: position, order: ASC }) {
      edges {
        node {
          workScopes {
            icon {
              localFile {
                ...FluidImage
              }
            }
            name {
              en
            }
          }
          project {
            name {
              en
            }
            url
            logo {
              localFile {
                ...FluidImage
              }
            }
            review {
              reviewerName
              shortStatement {
                en
              }
            }
            company {
              name
            }
            primaryImage {
              localFile {
                ...FluidImage
              }
            }
          }
        }
      }
    }
    staffMembers: allStrapiStaffMember(sort: { fields: position, order: ASC }) {
      edges {
        node {
          name
          role {
            en
          }
          chiefLevelRole
          profilePicture {
            localFile {
              ...FluidImage
            }
          }
          description {
            en {
              data {
                en
              }
            }
          }
          technicalSkills {
            name {
              en
            }
          }
          socialMedia {
            url
            type
            icon {
              localFile {
                ...FluidImage
              }
            }
          }
        }
      }
    }
    contactUsForm: strapiContactUsForm {
      budgetOptions {
        en
      }
      facebook {
        url
        icon {
          localFile {
            ...FluidImage
          }
        }
      }
    }
  }
`;

export default IndexPage_en;

const headerStrings = {
  header: {
    contactUs: 'Entre em contato',
  },
  menuItems: {
    howWeDo: 'Como Fazemos',
    ourServices: 'Nossos Serviços',
    successCases: 'Cases de Sucesso',
    ourFaces: 'Nossas Caras',
    privacyPolicy: 'Política de Privacidade',
  },
};

const copyrightStrings = {
  copyright: {
    subtitle: 'Todos os direitos reservados.',
  },
};

const contactUsStrings = {
  contactUs: {
    chooseContactForm: 'Escolha a melhor forma de entrar em contato',
    contactBySocialMedia: 'Converse via redes sociais',
    messageUs: 'Envie uma mensagem direta',
    form: {
      title: 'Um passo adiante',
      nameLabel: 'Nome',
      namePlaceholder: 'Como devemos te chamar?',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Qual o seu endereço de email?',
      phoneNumberLabel: 'Telefone',
      phoneNumberPlaceholder: 'Qual o seu número de telefone?',
      budgetLabel: 'Expectativa de investimento',
      budgetPlaceholder: 'O quanto pretende investir?',
      messageLabel: 'Mensagem',
      messagePlaceholder: 'O que você quer construir?',
      tipMessage: 'Vamos construir juntos',
      submitButton: 'Enviar',
      submitSuccessFeedbackTitle: 'Obrigado pelo contato!',
      submitSuccessFeedbackMessage: 'Retornaremos o mais rápido possível',
    },
  },
};

const cookieConsentStrings = {
  cookieConsent: {
    acceptButton: 'Aceitar',
    declineButton: 'Rejeitar',
    title: 'Sua privacidade é muito importante para nós',
    description:
      'Nós usamos cookies para melhorar sua experiência em nosso site e para fazer nossa análise de tráfego. Ao aceitar, você terá acesso a todas as funcionalidades do site. Se rejeitar, os cookies que não forem estritamente necessários serão desativados. Saiba mais em nossa ',
    cookiesTerms: 'Política de Cookies',
  },
};

export const homePageStrings = {
  ...headerStrings,
  ...copyrightStrings,
  ...contactUsStrings,
  ...cookieConsentStrings,
  firstSection: {
    callToActionButton: 'Tirar do papel',
    seeMoreButton: 'Ver mais',
  },
  secondSection: {
    preHeadline: 'Como',
    headline: 'Fazemos',
  },
  thirdSection: {
    preHeadline: 'Nossos',
    headline: 'Serviços',
    callToActionButton: 'Vamos conversar?',
  },
  forthSection: {
    preHeadline: 'Cases de',
    headline: 'Sucesso',
  },
  fifthSection: {
    preHeadline: 'Nossas',
    headline: 'Caras',
  },
  caseStudy: {
    actionTitle: 'Explorar',
  },
};

export const notFoundPageStrings = {
  ...headerStrings,
  ...contactUsStrings,
  ...cookieConsentStrings,
  meta: {
    title: 'Não encontrado',
  },
  content: {
    title: 'Está um pouco vazio por aqui...',
    subtitle: 'Não conseguimos encontrar a página que você está procurando',
    goHomeButton: 'Voltar para a home',
  },
};

export const privacyPolicyPageStrings = {
  ...headerStrings,
  ...copyrightStrings,
  ...cookieConsentStrings,
  ...contactUsStrings,
  meta: {
    title: 'Política de Privacidade',
  },
  content: {
    languageWarning:
      ' Due to legal reasons, parts of the privacy policy below are only available in Brazilian Portuguese.',
  },
};

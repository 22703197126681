import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const getBrowserLanguages = () => {
  if (typeof window === 'undefined') return [];

  const {
    languages,
    language,
    browserLanguage,
    userLanguage,
  } = window.navigator;
  return languages || [language || browserLanguage || userLanguage];
};

const getTLD = () => {
  if (typeof window === 'undefined') return null;

  const { hostname } = window.location;
  return hostname.substring(hostname.lastIndexOf('.') + 1).toLowerCase();
};

const tldToLang = {
  br: 'pt',
  co: 'en',
};

export default () => {
  const data = useStaticQuery(graphql`
    query SiteLanguagesQuery {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const { langs, defaultLangKey } = data.site.siteMetadata.languages;

    const tld = getTLD();
    if (tld && tldToLang[tld]) {
      return tldToLang[tld];
    }

    const userLangs = [...getBrowserLanguages(langs, defaultLangKey)];

    const lang = langs.find(appLang =>
      userLangs.some(userLang => userLang.startsWith(appLang))
    );
    if (!lang) {
      return defaultLangKey;
    }

    return lang;
  }, [data]);
};

const headerStrings = {
  header: {
    contactUs: 'Contact us',
  },
  menuItems: {
    howWeDo: 'How We Roll',
    ourServices: 'What We Offer',
    successCases: 'Case Studies',
    ourFaces: 'Meet The Crew',
    privacyPolicy: 'Privacy Policy',
  },
};

const copyrightStrings = {
  copyright: {
    subtitle: 'All rights reserved.',
  },
};

const contactUsStrings = {
  contactUs: {
    chooseContactForm: 'Choose your preferred way of getting in touch',
    contactBySocialMedia: 'Reach out via social media',
    messageUs: 'Send us a direct message',
    form: {
      title: 'One step closer',
      nameLabel: 'Name',
      namePlaceholder: 'How should we call you?',
      emailLabel: 'Email',
      emailPlaceholder: "What's your email address?",
      phoneNumberLabel: 'Phone Number',
      phoneNumberPlaceholder: "What's your phone number?",
      budgetLabel: 'Budget',
      budgetPlaceholder: 'What are your budget limitations?',
      messageLabel: 'Message',
      messagePlaceholder: "What's your project all about?",
      tipMessage: "Let's build together",
      submitButton: 'Submit',
      submitSuccessFeedbackTitle: 'Thanks for the getting in touch!',
      submitSuccessFeedbackMessage: "We'll get back to you as soon as possible",
    },
  },
};

const cookieConsentStrings = {
  cookieConsent: {
    acceptButton: 'Accept',
    declineButton: 'Reject',
    title: 'Your privacy is very important to us',
    description:
      'We use cookies to improve your experience on our website and to do our traffic analysis. By accepting, you will have access to all the functionalities of the site. If you decline, cookies that are not strictly necessary will be disabled. Learn more in our ',
    cookiesTerms: 'Cookies Policy',
  },
};

export const homePageStrings = {
  ...headerStrings,
  ...copyrightStrings,
  ...contactUsStrings,
  ...cookieConsentStrings,
  firstSection: {
    callToActionButton: 'Make it happen',
    seeMoreButton: 'Learn more',
  },
  secondSection: {
    preHeadline: 'How',
    headline: 'We Roll',
  },
  thirdSection: {
    preHeadline: 'What',
    headline: 'We Offer',
    callToActionButton: 'Get in touch',
  },
  forthSection: {
    preHeadline: 'What',
    headline: 'They Say',
  },
  fifthSection: {
    preHeadline: 'Meet',
    headline: 'The Crew',
  },
  caseStudy: {
    actionTitle: 'Check it out',
  },
};

export const notFoundPageStrings = {
  ...headerStrings,
  ...contactUsStrings,
  ...cookieConsentStrings,
  meta: {
    title: 'Not found',
  },
  content: {
    title: "It's a little empty around here...",
    subtitle: "We couldn't find the page you're looking for",
    goHomeButton: 'Go back home',
  },
};

export const privacyPolicyPageStrings = {
  ...headerStrings,
  ...copyrightStrings,
  ...cookieConsentStrings,
  ...contactUsStrings,
  meta: {
    title: 'Privacy Policy',
  },
  content: {
    languageWarning:
      ' Due to legal reasons, parts of the privacy policy below are only available in Brazilian Portuguese.',
  },
};

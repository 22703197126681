import React from 'react';
import IndexPt from './index.pt';
import IndexEn from './index.en';
import useUserLangOrDefault from '../hooks/useUserLangOrDefault';

export default () => {
  const lang = useUserLangOrDefault();

  if (lang === 'en') return <IndexEn />;

  return <IndexPt />;
};

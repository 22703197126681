import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import HomePage from '../components/pages/HomePage';
import { homePageStrings } from '../resources/strings/strings-pt';
import LanguageContext from '../contexts/LanguageContext';
import { StaticTranslationProvider } from '../contexts/StaticTranslationContext';
import StrapiDataContext from '../contexts/StrapiDataContext';
import Layout from '../components/common/Layout/Layout';

const IndexPage_pt = () => {
  const remoteData = useStaticQuery(remoteDataQuery);

  return (
    <StrapiDataContext.Provider value={remoteData}>
      <StaticTranslationProvider translations={homePageStrings}>
        <LanguageContext.Provider value="pt">
          <Layout>
            <HomePage data={remoteData} />
          </Layout>
        </LanguageContext.Provider>
      </StaticTranslationProvider>
    </StrapiDataContext.Provider>
  );
};

const remoteDataQuery = graphql`
  query GetHomePageContentPt {
    staticContent: strapiHomePage {
      mainSectionTitle {
        pt
      }
      mainSectionSubtitle {
        pt
      }
      methodologySubtitle {
        pt
      }
      methodologyText {
        pt
      }
      staffMemberText {
        pt
      }
    }
    methodologySteps: allStrapiMethodologyStep(
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title {
            pt
          }
          description {
            pt
          }
          image {
            localFile {
              ...FluidImage
            }
          }
        }
      }
    }
    services: allStrapiService(sort: { fields: position, order: ASC }) {
      edges {
        node {
          title {
            pt
          }
          subtitle {
            pt
          }
          icon {
            localFile {
              ...FluidImage
            }
          }
        }
      }
    }
    caseStudies: allStrapiCaseStudy(sort: { fields: position, order: ASC }) {
      edges {
        node {
          workScopes {
            icon {
              localFile {
                ...FluidImage
              }
            }
            name {
              pt
            }
          }
          project {
            name {
              pt
            }
            url
            logo {
              localFile {
                ...FluidImage
              }
            }
            review {
              reviewerName
              shortStatement {
                pt
              }
            }
            company {
              name
            }
            primaryImage {
              localFile {
                ...FluidImage
              }
            }
          }
        }
      }
    }
    staffMembers: allStrapiStaffMember(sort: { fields: position, order: ASC }) {
      edges {
        node {
          name
          role {
            pt
          }
          chiefLevelRole
          profilePicture {
            localFile {
              ...FluidImage
            }
          }
          description {
            pt {
              data {
                pt
              }
            }
          }
          technicalSkills {
            name {
              pt
            }
          }
          socialMedia {
            url
            type
            icon {
              localFile {
                ...FluidImage
              }
            }
          }
        }
      }
    }
    contactUsForm: strapiContactUsForm {
      budgetOptions {
        pt
      }
      facebook {
        url
        icon {
          localFile {
            ...FluidImage
          }
        }
      }
    }
  }
`;

export default IndexPage_pt;
